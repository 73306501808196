import React from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

export default function about() {
    return (
        <div>
            <Navbar />
            <main>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Personal Site - Joko Purnomo"  />
                <meta name="keywords" content="personal site,joko purnomo,Joko Purnomo,portfolio" />
                <meta name="author" content="Joko Purnomo" />
                <title>About | Joko Purnomo</title>
            </Helmet>
            
            <div className=" bg-white my-16">
                <div className="max-w-4xl mx-auto px-4 sm:px-6">

                    <h1 className="text-3xl font-bold">Tentang Joko Purnomo</h1>
                    
                    <div className="py-8 flex justify-center items-center">
                        <StaticImage className="border-2 rounded-full" src="../images/avatarku.jpg" alt="Avatar" layout="constrained" loading="eager" width={200} height={200} />
                    </div>

                    <p className="mt-5 text-base md:text-xl">
                        Saya dilahirkan di keluarga yang sederhana, tapi saya sangat bersyukur akan hal tersebut. karena dengan keluarga yang sederhana
                        ada banyak cerita di dalam keluarga saya. 
                    </p>

                    <p className="mt-5 text-base md:text-xl">
                        Saya adalah tipe orang yang sistematis dalam bekerja, penuh pertimbangan dalam menentukan sebuah solusi untuk sebuah masalah,
                        dan saya cenderung berpikir kritis dan rasional.
                    </p>

                    <p className="mt-5 text-base md:text-xl">
                        Saat ini saya adalah Seorang Web Developer di suatu perusahaan di Jakarta Selatan. Juga merupakan Mahasiswa di STMIK Himsya Semarang
                        dan Kontributor untuk Komunitas Klaten Paranoid.
                    </p>

                    <p className="mt-5 text-base md:text-xl">
                        Ketertarikan utama saya adalah terhadap semua hal yang berkaitan dengan Teknologi, karena ketertarikan tersebut,
                        saya seringkali menghabiskan waktu yang saya miliki hanya untuk mempelajari dunian Teknologi termasuk dunia Pemrograman. 
                    </p>

                    {/* Work Experience */}
                    <h1 className="text-3xl font-bold py-4 mt-6">Work Experience</h1>

                    <ul role="list" className="text-base md:text-xl px-2 py-3 list-disc mx-4">
                        <li>BackEnd Developer | PT. Janish Lintas Data <span className="float-right">Juni 2021 - Agustus 2022, Depok</span></li>
                    </ul>

                    {/* Skill */}
                    <h1 className="text-3xl font-bold py-4 mt-6">Skills</h1>

                    <ul role="list" className="text-base md:text-xl px-2 py-3 list-disc mx-4">
                        <li>PHP Native <span className="float-right">Intermediate</span></li>
                        <li>Javascript <span className="float-right">Intermediate</span></li>
                        <li>Laravel <span className="float-right">Intermediate</span></li>
                        <li>GatsbyJS <span className="float-right">Beginner</span></li>
                        <li>ReactJS <span className="float-right">Beginner</span></li>
                    </ul>

                    {/* Yang saya gunanakan */}

                    <h1 className="text-3xl font-bold py-4 mt-6">Yang saat ini saya gunakan</h1>

                    <ul role="list" className="text-base md:text-xl px-2 py-3 list-disc mx-4">
                        {/* <li>Laptop : Lenovo V310</li> */}
                        <li>Hosting & Domain : Netlify & Exabytes</li>
                        <li>Site Engine : GatsbyJS</li>
                        <li>Email : Gmail</li>
                        <li>SSL : Let's Encrypt</li>
                        <li>Notes : Notepad, Google Keep</li>
                        <li>Text Editor : Visual Studio Code, Sublime Text 3, Notepad++</li>
                        {/* <li>Design : Corel Draw X7, Adobe Photoshop</li> */}
                    </ul>

                </div>
            </div>

            </main>
            <Footer />
        </div>
    )
}
